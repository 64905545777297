const APP_ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT || 'base'

const envVariables = require(`./../config/environments/${APP_ENVIRONMENT}.json`)

const APP_STYLE_VARIABLES_FILE = envVariables.styleVariablesFile
const APP_I18N_FILE = envVariables.i18nFile
const APP_TITLE = envVariables.appTitle
const APP_FAVICON = envVariables.appFavicon
const APP_URL = envVariables.appUrl
const APP_LANGS = envVariables.appLangs
const APP_LANG = envVariables.appLang
const APP_LANG_FORCED = envVariables.appLangForced
const GOOGLE_GTAG = envVariables.googleGTag
const CAPTCHA_KEY = envVariables.captchaKey

// endpoints
const CHAT_WEBSOCKET = envVariables.endpoints.chatWebsocket
const PRESENTER_WEBSOCKET = envVariables.endpoints.presenterWebsocket
const CHAT_CORE = envVariables.endpoints.chatCore
const SSO_URL = envVariables.endpoints.ssoUrl
const BUY_GET_URL = envVariables.endpoints.buyGetUrl

// customizations
const AUTH_LOGO = envVariables.customizations.authLogo
const AUTH_LOGO_PLACE = envVariables.customizations.authLogoPlace
const AUTH_LOGO_WIDTH = envVariables.customizations.authLogoWidth
const AUTH_LOGO_TOP_OFFSET = envVariables.customizations.authLogoTopOffset
const AUTH_LOGO_BOTTOM_OFFSET = envVariables.customizations.authLogoBottomOffset
const AUTH_SHOW_IMAGE_OVER_CARD = envVariables.customizations.authShowImageOverCard
const HEADER_SHOW_POWERED_BY_AICA = envVariables.customizations.headerShowPoweredByAica
const HEADER_AVATAR = envVariables.customizations.headerAvatar

// features
const ENABLE_BUY_GET_FEATURE = envVariables.features.ENABLE_BUY_GET_FEATURE
const ENABLE_PRESENTER_FEATURE = envVariables.features.ENABLE_PRESENTER_FEATURE

// presenter
const SHOW_EMBED_PRODUCT_NAME = envVariables.features.presenter.showEmbedProductName
const SHOW_EMBED_PRODUCT_BRAND = envVariables.features.presenter.showEmbedProductBrand

module.exports = {
  APP_ENVIRONMENT,
  APP_STYLE_VARIABLES_FILE,
  APP_I18N_FILE,
  APP_TITLE,
  APP_FAVICON,
  APP_URL,
  APP_LANGS,
  APP_LANG,
  APP_LANG_FORCED,
  GOOGLE_GTAG,
  CAPTCHA_KEY,
  CHAT_WEBSOCKET,
  PRESENTER_WEBSOCKET,
  CHAT_CORE,
  SSO_URL,
  BUY_GET_URL,
  AUTH_LOGO,
  AUTH_LOGO_PLACE,
  AUTH_LOGO_WIDTH,
  AUTH_LOGO_TOP_OFFSET,
  AUTH_LOGO_BOTTOM_OFFSET,
  AUTH_SHOW_IMAGE_OVER_CARD,
  HEADER_SHOW_POWERED_BY_AICA,
  HEADER_AVATAR,
  ENABLE_BUY_GET_FEATURE,
  ENABLE_PRESENTER_FEATURE,
  SHOW_EMBED_PRODUCT_NAME,
  SHOW_EMBED_PRODUCT_BRAND
}

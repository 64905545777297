<template>
  <div class="chatBody" id="chatBody">
    <BuyGet v-if="isBuyGetFeatureEnabled" />
    <ChatInfo v-if="isPresenterFeatureEnabled" :singular="isPresenterFeatureEnabled" expanded>
      <template v-if="chatMode === 'default'">
        <img src="@/assets/img/icon-gift.svg" alt="Icon Gift">
        <p class="text--primary">
          {{ $t('presenter.welcome_message.0') }}
        </p>
        <button class="button--primary" @click="handleMessageOption('switchToPresenterMode')" :disabled="wsStatus !== 'finished'">
          <span class="icon">
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g
              clip-path="url(#clip0_1_2659)"><path
              d="M1.25 10.5C1.25 10.9148 1.58516 11.25 2 11.25H5.75V7.5H1.25V10.5ZM7.25 11.25H11C11.4148 11.25 11.75 10.9148 11.75 10.5V7.5H7.25V11.25ZM11.75 3.75H10.7633C10.9086 3.46641 11 3.15234 11 2.8125C11 1.67578 10.0742 0.75 8.9375 0.75C7.9625 0.75 7.33203 1.24922 6.52344 2.35078C5.71484 1.24922 5.08437 0.75 4.10938 0.75C2.97266 0.75 2.04688 1.67578 2.04688 2.8125C2.04688 3.15234 2.13594 3.46641 2.28359 3.75H1.25C0.835156 3.75 0.5 4.08516 0.5 4.5V6.375C0.5 6.58125 0.66875 6.75 0.875 6.75H12.125C12.3313 6.75 12.5 6.58125 12.5 6.375V4.5C12.5 4.08516 12.1648 3.75 11.75 3.75ZM4.10703 3.75C3.58906 3.75 3.16953 3.33047 3.16953 2.8125C3.16953 2.29453 3.58906 1.875 4.10703 1.875C4.57344 1.875 4.91797 1.95234 6.125 3.75H4.10703ZM8.9375 3.75H6.91953C8.12422 1.95703 8.45937 1.875 8.9375 1.875C9.45547 1.875 9.875 2.29453 9.875 2.8125C9.875 3.33047 9.45547 3.75 8.9375 3.75Z"
              fill="currentColor"/></g><defs><clipPath id="clip0_1_2659"><rect width="12" height="12" fill="white"
                                                                               transform="translate(0.5)"/></clipPath></defs></svg>
          </span>
          {{ $t('presenter.reset_chat_button') }}
        </button>
      </template>
      <template v-if="chatMode === 'presenter'">
        <p class="text--secondary">
          {{ $t('presenter.welcome_message.1') }}
        </p>
        <button class="button--secondary" @click="handleMessageOption('switchToDefaultMode')" :disabled="wsStatus !== 'finished'">
          {{ $t('presenter.ask_mall') }}
        </button>
      </template>
    </ChatInfo>
    <div v-if="!isPresenterFeatureEnabled" class="chatInside">
      <MessageTip
        v-for="(message, index) in messages"
        :index="index"
        :question="message.question"
        :answer="message.answer"
        :type="message.type"
        :link="message.link"
        :state="message.state"
        :key="message.id"
        @finished="scrollToEnd"
      />
      <MessageNotice v-if="wsStatus === 'limit_exceeded'" text="chat.limit_exceeded" />
      <MessageNotice v-if="wsStatus === 'validation_failed'" text="chat.validation_failed" />
      <MessageNotice v-if="wsStatus === 'language_not_allowed'" text="chat.language_not_allowed" />
      <MessageNotice v-if="wsStatus === 'moderation_error'" text="chat.moderation_error" />
      <MessageNotice v-if="wsStatus === 'temporary_unavailable'" text="chat.temporary_unavailable" />
    </div>
    <div v-else class="chatInside">
      <MessageTip
        v-for="(message, index) in messages"
        :index="index"
        :question="message.question"
        :answer="message.answer"
        :type="message.type"
        :link="message.link"
        :state="message.state"
        :embeds="message.embeds"
        :embeds-status="message.embeds_status"
        @select-option="handleMessageOption"
        :key="message.id"
      />

      <MessageNotice v-if="wsStatus === 'limit_exceeded'" text="chat.limit_exceeded" />
      <MessageNotice v-if="wsStatus === 'validation_failed'" text="chat.validation_failed" />
      <MessageNotice v-if="wsStatus === 'language_not_allowed'" text="chat.language_not_allowed" />
      <MessageNotice v-if="wsStatus === 'moderation_error'" text="chat.moderation_error" />
      <MessageNotice v-if="wsStatus === 'temporary_unavailable'" text="chat.temporary_unavailable" />
      <MessageEmbedModal
        v-if="messageDetectedPresentData"
        :data="messageDetectedPresentData"
        @close="closeModal"
        @select-option="handleMessageOption">
      </MessageEmbedModal>
    </div>
  </div>
</template>

<script>
import MessageTip from '../elements/MessageTip'
import MessageNotice from '../elements/MessageNotice'
import BuyGet from '../buy-get/Buy-Get'
import ChatInfo from '../elements/ChatInfo'
import MessageEmbedModal from '../elements/MessageEmbedModal.vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'

import Env from './../../env'

export default {
  name: 'Body',
  components: {
    BuyGet,
    MessageTip,
    MessageNotice,
    ChatInfo,
    MessageEmbedModal
  },
  props: {
    messages: {
      type: Array
    }
  },
  data () {
    return {
      bodyHeight: 0
    }
  },
  computed: {
    ...mapGetters({
      wsStatus: 'messages/getWsStatus',
      chatMode: 'chat/getChatMode'
    }),
    isBuyGetFeatureEnabled () {
      return Env.ENABLE_BUY_GET_FEATURE
    },
    isPresenterFeatureEnabled () {
      return Env.ENABLE_PRESENTER_FEATURE
    },
    messageDetectedPresentData () {
      if (this.wsStatus !== 'gift_redirect') {
        return null
      }

      return {
        title: this.$t('presenter.presenter_detected.title'),
        description: this.$t('presenter.presenter_detected.description'),
        picture: require('@/assets/img/presenter-modal-bg.png'),
        closable: false,
        class: 'detected-present',
        options: [
          {
            id: 'closeDetectedPresent',
            type: 'secondary',
            title: this.$t('presenter.presenter_detected.no')
          },
          {
            id: 'switchToPresenterMode',
            type: 'primary',
            title: this.$t('presenter.presenter_detected.yes'),
            icon: '<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_2659)"><path d="M1.25 10.5C1.25 10.9148 1.58516 11.25 2 11.25H5.75V7.5H1.25V10.5ZM7.25 11.25H11C11.4148 11.25 11.75 10.9148 11.75 10.5V7.5H7.25V11.25ZM11.75 3.75H10.7633C10.9086 3.46641 11 3.15234 11 2.8125C11 1.67578 10.0742 0.75 8.9375 0.75C7.9625 0.75 7.33203 1.24922 6.52344 2.35078C5.71484 1.24922 5.08437 0.75 4.10938 0.75C2.97266 0.75 2.04688 1.67578 2.04688 2.8125C2.04688 3.15234 2.13594 3.46641 2.28359 3.75H1.25C0.835156 3.75 0.5 4.08516 0.5 4.5V6.375C0.5 6.58125 0.66875 6.75 0.875 6.75H12.125C12.3313 6.75 12.5 6.58125 12.5 6.375V4.5C12.5 4.08516 12.1648 3.75 11.75 3.75ZM4.10703 3.75C3.58906 3.75 3.16953 3.33047 3.16953 2.8125C3.16953 2.29453 3.58906 1.875 4.10703 1.875C4.57344 1.875 4.91797 1.95234 6.125 3.75H4.10703ZM8.9375 3.75H6.91953C8.12422 1.95703 8.45937 1.875 8.9375 1.875C9.45547 1.875 9.875 2.29453 9.875 2.8125C9.875 3.33047 9.45547 3.75 8.9375 3.75Z" fill="currentColor"/></g><defs><clipPath id="clip0_1_2659"><rect width="12" height="12" fill="white" transform="translate(0.5)"/></clipPath></defs></svg>'
          }
        ]
      }
    }
  },
  watch: {
    bodyHeight (n, o) {
      if (n !== 0) {
        setTimeout(() => {
          this.scrollToEnd()
        }, 100)
      }
    }
  },
  created () {
    window.addEventListener('resize', this.calculateBodyHeight)
  },
  mounted () {
    this.setLocationIdWrapper(new URL(window.location).searchParams.get('location'))
    this.scrollToEnd()
    this.calculateBodyHeight()
    this.bodyHeight = document.querySelector('.chatBody').clientHeight
  },
  updated () {
    this.scrollToEnd()
  },
  methods: {
    ...mapActions({
      calculateBodyHeight: 'chat/calculateBodyHeight',
      scrollToEnd: 'chat/scrollToEnd',
      switchChatMode: 'chat/switchChatMode',
      setLocationId: 'chat/setLocationId'
    }),
    ...mapMutations({
      setWsStatus: 'messages/setWsStatus'
    }),
    handleMessageOption (option) {
      switch (option) {
        case 'switchToPresenterMode':
          this.switchChatMode('presenter')
          break
        case 'switchToDefaultMode':
          this.switchChatMode('default')
          break
        case 'closeDetectedPresent':
          this.setWsStatus('finished')
          break
        default:
          break
      }
    },
    setLocationIdWrapper (data) {
      this.setLocationId(data)
    }
  }
}
</script>

<style scoped lang="scss">
.chatBody {
  position: fixed;
  left: 0;
  right: 0;
  overflow: auto;
  .chatInside {
    padding: 0 15px 0 15px;
    overflow: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
